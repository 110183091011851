import React, { useState } from 'react'
import axios
 from 'axios';
function DisplayData({ _id, type, id, text, upvote, downvote, suggestion}) {

  let u = 0, 
  v = 0,
  keyId = String(_id) + String(id) + type,
  [upv, setUpv] = useState(upvote),
  [dnv, setDnv]     = useState(downvote),
  [sg, setSg]       = useState(suggestion);

  if(upv!==0 || dnv!==0){
    u = (upv/(upv+dnv)) * 100
    v = (dnv/(upv+dnv)) * 100
  }
  
  async function UpVote(){
    try{   
      await axios.post('https://iias.harriersys.com/iias-api/updateData', 
        {
          '_id'  : _id,
          'type' : type,
          'id'   : id,
          'vote' : 'upvote',
        });
        setUpv(upv+1)
    }catch(error) {}  
  }

  async function DownVote(){
    try{      
        await axios.post('https://iias.harriersys.com/iias-api/updateData', 
        {
          '_id'  : _id,
          'type' : type,
          'id'   : id,
          'vote' : 'downvote',
        });
        setDnv(dnv+1)
    }catch(error) {}  
  }

  async function addSuggestion(keyId){
    try{
      let temp = document.getElementById(keyId).value
        if(temp.length>0){
          await axios.post('https://iias.harriersys.com/iias-api/addSuggestion', 
          {
            '_id'  : _id,
            'type' : type,
            'id'   : id,
            'suggestion' : temp
          });
          document.getElementById(keyId).value = ""
          setSg(sg.push(temp))
        }else{
          alert("Suggestion cannot be empty")
        }
    }catch(error) {}  
  }

  return (
    <div className='addBox'>
      <p> { text } </p>
      <hr></hr>
      <div className='style-button'>
        <button type='button' className='btn btn-sm btn-success' onClick={ () => UpVote()   } >Upvote</button>
        <button type='button' className='btn btn-sm btn-danger'  onClick={ () => DownVote() } >Downvote</button>
      </div>

      <div className='progress' role='progressbar'>
        <div className='progress-bar bg-success' style={{ width : u+'%'}}>{ u.toPrecision(4) }%</div>
        <div className='progress-bar bg-danger' style={{width : v+'%'}}>{ v.toPrecision(4) }%</div>
      </div>

      <hr></hr>

        <div className='input-group mb-3'>
        <textarea className='form-control' placeholder='Enter your suggestion here!' id= { keyId } />
          <button className='btn btn-warning' onClick={() => addSuggestion(keyId)}>Add</button>
        </div>

      <hr></hr>

      <b>Previous Suggestions</b>
      <hr></hr>
      {
        suggestion.map((curElem)=>{
          return <div> 
            { curElem } 
            <hr></hr>
          </div>
        })
      }

    </div>    
  )
}

export default DisplayData
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Accordion from '../Accordion/Accordion'

function Cards() {
    let [data , setData] = useState([])
    let [year, setYear]  = useState(-1)

    useEffect(()=>{
        async function getData() {
            try{
                const response = await axios.get('https://iias.harriersys.com/iias-api/getYears');
                const result = await response.data
                setData(result.sort(function(a,b){
                    return b.year - a.year
                }))
            }catch(error) {
                console.log(error)
            }       
        }
        getData()
    }, [])

    if(data.length !== 0){
        return (
            <div style={{ padding:"10px" }}>
                <div style={{display:"flex", flexWrap:"wrap"}}>
                    {            
                        data.map((curElem) => {
                            return <button className="btn btn-danger btn-lg" onClick={() => setYear(curElem.year)} style={{margin:"10px"}}> { curElem.year } </button>
                        })
                    }
                </div>
                <hr></hr>
                <Accordion year = { year } />
            </div>
        )
    }else{
        return (
            <button class="btn btn-warning" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading 
            </button>
        )
    }
}

export default Cards
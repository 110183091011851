import React from 'react'
import './Header.css'

function Header() {
  return (
      <nav className="title text-center">
          Annual General Meeting (AGM) Notice Summary
      </nav>
  )
}

export default Header
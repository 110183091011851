import './Accordion.css'
import axios from 'axios'
import React, { useState } from 'react'
import MyAccordion from './MyAccordion'

function Accordion(props) {
    let [data , setData] = useState([])
    let [year , setYear] = useState(-1)

    if (year !== props.year){
        async function getData() {
            try{
                setYear(props.year)
                const response = await axios.get('https://iias.harriersys.com/iias-api/getData', {
                    params: {
                        year : props.year
                    }
                });
                const result = await response.data
                setData(result)
            }catch(error) {
                console.log(error)
            }        
        }
        getData()
    }

    if(data.length!==0){
        return(
            <div className='dataBody'>
                {
                    data.map( (curElem) => {
                            return <MyAccordion key = {curElem._id} { ...curElem }></MyAccordion>
                    })   
                }
            </div>
        )
    }else{
        return (
            <div>
                <button class="btn btn-success" type="button" disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Select year
                </button>
            </div>
        )
    }
}

export default Accordion
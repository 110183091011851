import React, { useState } from 'react'
import DisplayData from './displayData'

function MyAccordion( {_id, Company, Date, OrdinaryData, SpecialData}) {
    const [show, setShow] = useState(false)
    return (
        <div className='data'>
            <button className='CompanyName btn btn-outline-dark text-start' onClick={() => setShow(!show)}><b>{ Company }</b></button>
            
            {
                show && <div className='lil-mar'> 
                <hr></hr>             
                    <div><h5>Date : { Date }</h5></div> 
                    <h5>Ordinary Business :</h5>
                    <div>
                        {
                            OrdinaryData.map((curElem) => {
                                return <DisplayData _id = { _id } type = "OrdinaryData" {...curElem}></DisplayData>
                            }) 
                        }
                    </div>  
                    <h5>Special Business :</h5>
                    <div>
                        {
                            SpecialData.map((curElem) => {
                                return <DisplayData _id = { _id } type = "SpecialData" {...curElem}></DisplayData>
                            })
                        }
                    </div>
                    
                </div>
            }        
        </div>
    )
}

export default MyAccordion
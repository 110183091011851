import React from 'react'

function Footer() {
  return (
      <nav className="navbar-light bg-dark text-light text-center">
          ©Harrier-2023
      </nav>
  )
}

export default Footer
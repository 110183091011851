import Header from './Header/Header';
import Footer from './Footer/Footer';
import Cards from './Cards/Cards';

function App() {
  return (
    <div>
      <Header/>
      <Cards />
      <Footer/>
    </div>
  );
}

export default App;